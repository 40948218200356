<template>
    <div class="table-credit-stats">
        <div class="heading-container">
            <h4 class="title">{{$t('credits.table.stats.title')}}</h4>
        </div>

        <table class="nomargin-bottom">
            <tr>
                <th>{{$t('credits.table.stats.th.stat')}}</th>
                <th>{{$t('credits.table.stats.th.value')}}</th>
            </tr>

            <tr v-if="loaded && data.creditsPurchased" class="no-hover">
                <td>{{$t('credits.table.stats.td.creditsPurchased')}}</td>
                <td>{{ data.creditsPurchased }} crédits</td>
            </tr>
            <tr v-if="loaded && data.creditsSpent" class="no-hover">
                <td>{{$t('credits.table.stats.td.creditsSpent')}}</td>
                <td>{{ data.creditsSpent }} crédits</td>
            </tr>
            <tr v-if="loaded && data.averageMatchCost" class="no-hover">
                <td>{{$t('credits.table.stats.td.averageMatchCost')}}</td>
                <td>{{ data.averageMatchCost }} crédits</td>
            </tr>
             <tr v-if="loaded && data.matchesUnlocked" class="no-hover">
                <td>{{$t('credits.table.stats.td.matchesUnlocked')}}</td>
                <td>{{ data.matchesUnlocked }} candidats</td>
            </tr>

            <tr class="no-hover">
                <td v-if="!loaded" class="loading" colspan="9"></td>
                <td v-if="loaded && data.length === 0" class="bold" colspan="4">{{$t('credits.table.purchased.noTransactions')}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: 'CreditStatsTable',

    data() {
        return {
            loaded: false,
            data: {}
        }
    },

    methods: {
        getCreditStats: function() {
            const self = this;

            self.$axios
                .get('credits/stats')
                .then(response => {
                    if(response.status == 200) {
                        self.data = response.data;
                        self.loaded = true;
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                    
                    self.loaded = true;
                });
        }
    },

    mounted() {
        const self = this; self.getCreditStats();
    }

}
</script>

