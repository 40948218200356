<template>
    <div>
        <table class="nomargin-bottom">
            <tr>
                <th>{{$t('credits.table.purchased.th.date')}}</th>
                <th>{{$t('credits.table.purchased.th.creditsPurchased')}}</th>
                <th>{{$t('credits.table.purchased.th.transactionType')}}</th>
            </tr>
            <tr v-for="row in data" :row="row" :key="row._id" class="no-hover">
                <td class="bold">{{ row.time | moment("DD/MM/YYYY, H:mm") }}</td>
                <td>{{ row.creditsPurchased }}</td>
                <td>{{$t(`credits.table.purchased.transactionTypes.${row.type}`)}}</td>
            </tr>
            <tr class="no-hover">
                <td v-if="!loaded" class="loading" colspan="3"></td>
                <td v-if="loaded && data.length === 0" class="bold" colspan="3">{{$t('credits.table.purchased.noTransactions')}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: 'PurchasedCreditsHistoryTable',

    props: {
        data: {
            type: Array,
            default() {
                return []
            }
        },

        loaded: {
            type: Boolean,
            default: false
        }
    }
}
</script>

