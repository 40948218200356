<template>
    <div v-if="loaded" :class="['card card_payment_method animate__animated animate__fadeIn', { embedded }]">
        <div v-if="!embedded" class="card-heading">
            <p class="title">{{$t('payments.card.payment_method.title')}}</p>
        </div>

        <div v-if="has_payment_method" class="card-body">
            <div class="info">
                <div class="payment_method">
                    <font-awesome-icon :icon="payment_icon" />
                    <p class="last_4"> •••• {{payment_method.last_4}}</p>
                </div>

                <div class="expiration">
                    <p class="label">{{$t('payments.card.payment_method.expiration')}}</p>
                    <p class="exp">{{`${payment_method.exp_month} / ${payment_method.exp_year}`}}</p>
                </div>
            </div>

            <button v-if="!embedded" :class="['cta cta-outline dark cta-slim', { loading }]" @click="remove()">{{$t('payments.card.payment_method.remove')}}</button>
        </div>

        <div v-if="!has_payment_method && embedded" class="card-body">
            <p v-if="notify_save_progress" class="no_payment_method" v-html="$t('payments.card.payment_method.no_payment_method_progress')"></p>
            <p v-else class="no_payment_method" v-html="$t('payments.card.payment_method.no_payment_method')"></p>
        </div>

        <div v-if="!embedded" class="card-footer">
            <button v-if="loaded && !has_payment_method" class="cta cta-primary cta-slim" @click="$emit('open')">{{$t('payments.card.payment_method.add_payment_method')}}</button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'PaymentMethodCard',

    props: {
        embedded: { type: Boolean, default: false },
        notify_save_progress: { type: Boolean, default: false },
    },

    data() {
        return {
            payment_method: null,
            has_payment_method: false,
            loaded: false,
            loading: false,
        }
    },

    computed: {
        payment_icon: function() {
            const self = this;
            if(self.has_payment_method) {
                if(self.payment_method.brand === 'visa') { return ['fab', 'cc-visa'] }
                if(self.payment_method.brand === 'mastercard') { return ['fab', 'cc-mastercard'] }
                if(self.payment_method.brand === 'amex') { return ['fab', 'cc-amex'] }
                return ['fa', 'credit-card'];
            }

            return []
        }
    },

    methods: {
        ...mapActions(['setToken']),

        get_payment_method: function() {
            const self = this;

            self.$axios
                .get('/payments/method')
                .then(response => {
                    if(response.status == 200){
                        self.has_payment_method = response.data.has_payment_method;
                        if(self.has_payment_method) {
                            self.payment_method = response.data.payment_method;
                        }

                        self.$emit('update_has_payment_method', self.has_payment_method);
                        
                        self.$nextTick(() => {
                            self.$emit('loaded');
                            self.loaded = true;
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }

                    self.$nextTick(() => {
                        self.$emit('loaded');
                        self.loaded = true;
                    })
                });
        },

        remove: function() {
            const self = this;

            if(self.payment_method.id) {
                self.loading = true;

                self.$axios
                    .post('/payments/method/remove', { payment_id: self.payment_method.id })
                    .then(response => {
                        if(response.status == 200) {
                            self.setToken(response.data);
                                
                            self.$toasted.success(
                                self.$t('notifications.paymentMethodRemoved'),
                                { icon: 'circle-check' }
                            );

                            self.loading = false;
                            self.get_payment_method();
                        }
                    })
                    .catch(error => {
                        self.loading = false;
                        
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                    });
            }
        }
    },

    mounted() {
        const self = this;
        self.get_payment_method();
    }
}
</script>

