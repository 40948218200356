<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded }">
        <div class="heading-container">
            <h1 class="title">{{$t('pages.credits.title')}}</h1>
        </div>

        <div class="row">
            <div class="col6">
                <PurchaseCredits @purchased_credits="get_available_credits()" />
            </div>

            <div class="col6">
                <AvailableCreditsCard :availableCredits="available_credits" />
                <CreditStatsTable />
            </div>
        </div>
    
        <div class="row">
            <div class="col12">
                <CreditTransactionHistoryCard />
            </div>
        </div>
    </layout>
</template>

<script>
import { bus } from '../../main';

import Layout from '../../components/Layout.vue';
import PurchaseCredits from '../../components/Cards/Credits/PurchaseCreditsCard.vue'
import AvailableCreditsCard from '../../components/Cards/Credits/AvailableCreditsCard.vue'
import CreditTransactionHistoryCard from '../../components/Cards/Credits/CreditTransactionHistoryCard.vue'
import CreditStatsTable from '../../components/Tables/CreditStatsTable.vue'

export default {
    name: 'Credits',

    components: {
        Layout,
        PurchaseCredits,
        AvailableCreditsCard,
        CreditTransactionHistoryCard,
        CreditStatsTable
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'credits',
            classes: {
                dashboard: true,
                credits: true
            },

            available_credits: 0,
            loaded: false
        }
    },

    methods: {
        get_available_credits: function() {
            const self = this;

            self.$axios
                .get('credits')
                .then(response => {
                    console.log(response);
                    if(response.status == 200) {
                        self.available_credits = response.data;
                        self.loaded = true;
                        
                        self.$nextTick(() => {
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        }
    },

    mounted() {
        const self = this; self.get_available_credits();
    }
};
</script>