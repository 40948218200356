<template>
    <div>
        <div class="heading-container mt-36">
            <h2 class="title">{{$t('credits.card.history.title')}}</h2>
            <div class="cta-container">
                <div class="cta-group nomargin">
                    <button :class="['cta cta-option cta-slim', { active: choice === 'purchased' }]" @click="choice = 'purchased'">{{$t('credits.card.history.cta.purchased')}}</button>
                    <button :class="['cta cta-option cta-slim', { active: choice === 'spent' }]" @click="choice = 'spent'">{{$t('credits.card.history.cta.spent')}}</button>
                </div>
            </div>
        </div>

        <PurchasedCreditsHistoryTable v-if="choice === 'purchased'" :data="purchasedCreditsHistory" :loaded="loaded" />
        <SpentCreditsHistoryTable v-if="choice === 'spent'" :data="spentCreditsHistory" :loaded="loaded" />
    </div>
</template>

<script>
import PurchasedCreditsHistoryTable from '../../Tables/PurchasedCreditsHistoryTable.vue'
import SpentCreditsHistoryTable from '../../Tables/SpentCreditsHistoryTable.vue'

export default {
    name: 'CreditTransactionHistoryCard',

    components: {
        PurchasedCreditsHistoryTable,
        SpentCreditsHistoryTable
    },
    
    data() {
        return {
            choice: 'purchased',

            purchasedCreditsHistory: [],
            spentCreditsHistory: [],

            loaded: false,
        }
    },

    methods: {
        getPurchasedCreditsHistory: function() {
            const self = this;
            self.$axios
                .get('credits/purchased/history')
                .then(response => {
                    if(response.status == 200) {
                        self.purchasedCreditsHistory = response.data;
                        self.loaded = true;
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }

                    self.loaded = true;
                });
        },

        getSpentCreditsHistory: function() {
            const self = this;
            self.$axios
                .get('credits/spent/history')
                .then(response => {
                    if(response.status == 200) {
                        self.spentCreditsHistory = response.data;
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },
    },

    mounted() {
        const self = this; 
        
        self.getPurchasedCreditsHistory();
        self.getSpentCreditsHistory();
    }
}
</script>

