<template>
    <div :class="['payments card card-purchase-credits animate__animated animate__fadeIn', { embedded }]">
        <div class="card-body">
            <div class="question-wrapper">
                <div class="question purchase-credits column">
                    <div class="label-container">
                        <p v-if="embedded">{{$t('credits.card.purchase.credits.title.notEnoughCredits')}}</p>
                        <p v-else>{{$t('credits.card.purchase.credits.title.default')}}</p>
                    </div>
                    
                    <div :class="['credits-container', error('amount')]">
                        <div v-if="embedded && missingCredits !== 1000" :class="['choice', { active: data.amount === missingCredits }]" @click="data.amount = missingCredits">
                            <span class="amount">
                                <font-awesome-icon :class="{ active: data.amount === missingCredits }" :icon="['fa', data.amount === missingCredits ? 'circle-check' : 'circle']" />
                                {{$t('credits.card.purchase.credits.amount', { amount: missingCredits.toLocaleString("en-US") })}}
                            </span>
                            <span class="price">{{ calculateMissingCreditsCost }}</span>
                        </div>
                        <div :class="['choice', {active: data.amount === 1000}]" @click="data.amount = 1000">
                            <span class="amount">
                                <font-awesome-icon :class="{ active: data.amount === 1000 }" :icon="data.amount === 1000 ? ['fa', 'circle-check'] : ['far', 'circle']" />
                                {{$t('credits.card.purchase.credits.amount', {amount: '1,000'})}}
                            </span>
                            <span class="price">
                                <span v-if="embedded && missingCredits !== 1000" class="rebate">10% de rabais</span>
                                12,79$
                            </span>
                        </div>
                        <div :class="['choice', {active: data.amount === 5000}]" @click="data.amount = 5000">
                            <span class="amount">
                                <font-awesome-icon :class="{ active: data.amount === 5000 }" :icon="data.amount === 5000 ? ['fa', 'circle-check'] : ['far', 'circle']" />
                                {{$t('credits.card.purchase.credits.amount', {amount: '5,000'})}}
                            </span>
                            <span class="price">
                                <span v-if="embedded && missingCredits !== 1000" class="rebate">20% de rabais</span>
                                <span v-else class="rebate">12% de rabais</span>
                                56,79$
                            </span>
                        </div>
                        <div :class="['choice', {active: data.amount === 10000}]" @click="data.amount = 10000">
                            <span class="amount">
                                <font-awesome-icon :class="{ active: data.amount === 10000 }" :icon="data.amount === 10000 ? ['fa', 'circle-check'] : ['far', 'circle']" />
                                {{$t('credits.card.purchase.credits.amount', {amount: '10,000'})}}
                            </span>
                            <span class="price">
                                <span v-if="embedded && missingCredits !== 1000" class="rebate">35% de rabais</span>
                                <span v-else class="rebate">25% de rabais</span>
                                95,79$
                            </span>
                        </div>
                    </div>

                    <p class="notice">{{$t('credits.card.purchase.notice')}}</p>
                    
                    <FormError :data="validation.amount" />
                </div>
            </div>
        </div>

        <div class="card-footer">
            <div class="multi-select-container">
                <p>{{$t('credits.multiselect.paymentMethod')}}</p>
                <PaymentMethodCard :embedded="true" :notify_save_progress="notify_save_progress" @update_has_payment_method="has_payment_method = $event" />
            </div>

            <div class="cta-container">
                <button :class="['cta cta-primary cta-slim', { loading, disabled: !has_payment_method }]" @click="purchaseCredits()">{{$t('credits.card.purchase.submit')}}</button>
                <button v-if="embedded" :class="['cta cta-outline cta-slim dark']" @click="cancel()">{{$t('credits.card.purchase.cancel')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import FormError from '../../../components/Utils/FormError.vue';
import PaymentMethodCard from '../Payments/PaymentMethodCard.vue';

export default {
    name: 'PurchaseCreditsCard',

    components: {
        FormError,
        PaymentMethodCard
    },

    props: {
        embedded: { type: Boolean, default: false },
        notify_save_progress: { type: Boolean, default: false },
        missingCredits: { type: Number, default: 0 },
        creditUnitCost: { type: Number, default: 0 }
    },

    data() {
        return {
            data: {
                amount: 0,
            },

            validation: {
                amount: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                }
            },

            formValidated: false,
            loading: false,
            has_payment_method: null
        }
    },

    computed: {
        calculateMissingCreditsCost: function() {
            const self = this;
            return `${(self.missingCredits * self.creditUnitCost).toFixed(2)}$`.replace('.', ',');
        }
    },

    methods: {
        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false

                if(!self.data[val] )
                    self.validation[val].error = true;
            });

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error)
                    self.formValidated = false
            });
        },

        purchaseCredits: function() {
            const self = this;

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;

                self.$axios
                    .post(`/credits/purchase`, {...self.data, type: 'single'})
                    .then(response => {
                        if(response.status == 200){
                            self.$toasted.success(
                                self.$t('notifications.creditsPurchasedSuccess', {amount: self.data.amount}),
                                { icon: 'circle-check' }
                            );

                            self.$emit('purchased_credits');
                            self.data.amount = 0;

                            self.$nextTick(() => {
                                self.loading = false;
                                self.formValidated = false;
                            })
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        cancel: function() {
            const self = this;
            self.$emit('cancel');
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        }
    }
}
</script>

