<template>
    <div>
        <table class="nomargin-bottom">
            <tr>
                <th>{{$t('credits.table.spent.th.date')}}</th>
                <th>{{$t('credits.table.spent.th.creditsConsumed')}}</th>
                <th>{{$t('credits.table.spent.th.type')}}</th>
                <th>{{$t('credits.table.spent.th.action')}}</th>
            </tr>
            <tr v-for="row in data" :row="row" :key="row._id" class="no-hover">
                <td class="bold">{{ row.time | moment("DD/MM/YYYY, H:mm") }}</td>
                <td>{{ row.creditsConsumed }}</td>
                <td>{{ $t(`credits.table.spent.td.type.${row.type}`) }}</td>
                <td class="see-receipt"><span class="gray" @click="go_to_entity(row)">{{$t(`credits.table.spent.td.action.${row.type}`)}}</span></td>
            </tr>
            <tr class="no-hover">
                <td v-if="!loaded" class="loading" colspan="4"></td>
                <td v-if="loaded && data.length === 0" class="bold" colspan="4">{{$t('credits.table.spent.noTransactions')}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    name: 'SpentCreditsHistoryTable',

    props: {
        data: {
            type: Array,
            default() {
                return []
            }
        },

        loaded: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        go_to_entity: function(row) {
            const self = this; 
            const { type, entityId } = row;
            
            if(type === 'match') {
                self.$utils._navigate_to_name_with_params(`work-provider-match-detail`, { id: entityId })
            } else if (type === 'recommendations.unpublished') {
                if(row.published) {
                    self.$utils._navigate_to_name_with_params(`work-provider-offer`, { id: row.published_offer_id, state: 'consult' })
                }else {
                    self.$utils._navigate_to_name_with_params(`create-work-provider-offer`, { id: row.entityId })
                }
            } else {
                self.$utils._navigate_to_name_with_params(`work-provider-offer`, { id: entityId, state: 'consult' })
            }
        }
    }
}
</script>

