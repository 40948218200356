<template>
    <div :class="['card card-available-credits animate__animated animate__fadeIn', { hidden: !availableCredits}]">
        <div class="card-body">
            <div class="question-wrapper">
                <div class="question available-credits">
                    <div class="label-container">
                        <p>{{$t('credits.card.available.credits.title')}}</p>
                    </div>

                    <span class="available-credits">{{availableCredits}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AvailableCreditsCard',
    
    props: {
        availableCredits: {
            type: Number,
            default() {
                return 0
            }
        }
    }
}
</script>

